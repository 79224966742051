.about_me{
    align-items: center;
    justify-content: space-around;
}

.description_wrapper {
    gap: 2.77vw;
    padding: 5.138vw 10.625vw;
    display: flex;
    flex-direction: column;
}

.comment {
    color: #DEDEDE;
    font-family: 'Urbanist', sans-serif;
    font-weight: 500;
    font-size: 38px;
}

.top {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom {
    font-size: 35px;
}

.box_content_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.about_me_img {
    width: 27.08vw;
    height: 27.08vw;
    border-radius: 193px;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5.138vw;
}

.header_title {
    color: #A9A9A9;
    font-family: 'Urbanist', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.header_ic_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.header_ic {
    cursor: pointer;
}

.company_row {
    width: 100%;
    height: 156px;
    display: flex;
    flex-direction: row;
    background: #484E53;
    border: #484E53 solid 1px;
    gap: 1px;
}