.company_cell_wrapper {
    background: #0A0A0A;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.company_title {
    font-family: 'Urbanist', sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: #FFFFFF;
}

.job_description {
    font-family: 'Urbanist', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #484E53;
}