#root {
    background: #0A0A0A;
    overflow-x: hidden;
}

.fade-in {
    opacity: 1!important;
    transition: opacity 0.5s ease-in-out;
}

.fade-in-initial {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}