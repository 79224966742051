.key_skill_cell_wrapper {
    width: 23.26vw;
    height: 23.88vw;
    border: #484E53 solid 1px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 2.569vw;
    gap: 1.38vw;
}

.skill_image {
    width: 2.77vw;
    height: 2.77vw;
}

.skill_title {
    font-family: 'Urbanist', sans-serif;
    font-size: 28px;
    font-weight: 500;
    color: #DEDEDE;
}

.skill_description {
    font-family: 'Urbanist', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #A9A9A9;
}