.key_skills {
    align-items: center;
    justify-content: flex-start;
    padding: 5.138vw 10.625vw;
    display: flex;
    flex-direction: column;
    height: auto;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5.138vw;
    margin-bottom: 1.38vw;
}

.key_skills_description{
    color: #DEDEDE;
    font-family: 'Urbanist', sans-serif;
    font-weight: 500;
    font-size: 38px;
    width: 85%;
    align-self: flex-start;
    margin-bottom: 4.16vw;
}

.skill_cell_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 3.75vw;
    margin-bottom: 3.47vw;
}