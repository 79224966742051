.project {
    align-items: center;
    justify-content: flex-start;
    padding: 5.138vw 10.625vw;
    display: flex;
    flex-direction: column;
    height: auto;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5.138vw;
    margin-bottom: 1.38vw;
}

.header_title {
    color: #A9A9A9;
    font-family: 'Urbanist', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.header_ic_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.project_description {
    color: #DEDEDE;
    font-family: 'Urbanist', sans-serif;
    font-weight: 500;
    font-size: 38px;
    width: 85%;
    align-self: flex-start;
    margin-bottom: 4.16vw;
}

.project_cell_row {
    display: flex;
    flex-direction: row;
    gap: 4.16vw;
    margin-bottom: 4.16vw;
}

.project_expend_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project_expend_button {
    width: 14.86vw;
    height: 3.472vw;
    border: #484E53 1px solid;
    font-family: 'Urbanist', sans-serif;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}