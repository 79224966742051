.project_cell_wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.38vw;
}

.project_cell_image {
    width: 100%;
}

.button_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project_cell_button {
    border: #484E53 1px solid;
    border-radius: 51px;
    font-family: 'Urbanist', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #A9A9A9;
    width: 8.33vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project_cell_button_img {
    cursor: pointer;
}