.kv_button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.763vw 2.22vw;
    border: rgba(169,169,169,0.400) 1px solid;
    border-radius: 51px;
}

.kv_button_text {
    color: #FFFFFF;
    font-family: 'Urbanist', sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-right: 5px;
}