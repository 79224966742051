.wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2.5vw 10.625vw;
    position: fixed;
    top: 0;
    box-sizing: border-box;
    background: #0A0A0A;
}

.title_wrapper {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.title {
    font-family: 'Inter', sans-serif;
    color: white;
    font-weight: 700;
    font-size: 24px;
}

.dot {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #D46868;
}

.gnb_icon {
    width: 2.18vw;
    height: 1.458vw;
    cursor: pointer;
}