.container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background: #0A0A0A;
}

.kv {
    gap: 2.77vw;
    padding: 2.5vw 10.625vw;
    justify-content: center;
    align-items: center;
}

.kv_title_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.kv_title_row {
    display: flex;
    flex-direction: row;
}

.kv_title_row > div {
    font-family: 'Urbanist', sans-serif;
}

.dark {
    color: #5F5F5F;
    font-weight: 500;
    font-size: 75px;
}

.light {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 75px;
}


.kv_scroll_down_btn {
    position: absolute;
    bottom: 4.861vw;
    right: 11.66vw;
}